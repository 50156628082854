import axios from "axios";
import { TenantOptions } from "fe-shared/src/types/domain/User";

export type ExternalUserTenantOptions = TenantOptions;

export const getExternalTenantOptions = (userId: number | undefined): Promise<ExternalUserTenantOptions> => {
    const params = userId ? { userId: userId } : {};
    
    return axios
      .get(`getTenantOptions`, { params })
      .then((res) => res.data);
  };
  