import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";

import LoginView from "routes/Login";

import UserContext from "contexts/userContext";

import { get, set } from "fe-shared/src/helpers/storage";
import isDisabledRoute from "fe-shared/src/helpers/isDisabledRoute";
import { ExternalApp, Loader, NotFoundPage } from "fe-shared/src/components";
import useAccessRejectedInterceptor from "fe-shared/src/hooks/useAccessRejectedInterceptor";
import useAuthorizationHeader from "fe-shared/src/hooks/useAuthorizationHeader";
import useGlobalErrorHandler from "fe-shared/src/hooks/useGlobalErrorHandler";
import useHistoryListener from "fe-shared/src/hooks/useHistoryListener";
import useSentryIdentify from "fe-shared/src/hooks/useSentryIdentify";
import useTenantOptions from "fe-shared/src/hooks/useTenantOptions";

import CreatePin from "routes/CreatePin";
import CreatePassword from "routes/CreatePassword";
import ExternalAppsView from "routes/ExternalApps";
import ForgotPasswordView from "routes/ForgotPasswordView";
import AccountCreated from "routes/AccountCreated";
import InactiveInvite from "routes/InactiveInvite";
import AcceptWebInvite from "routes/AcceptWebInvite";
import AcceptMobileInvite from "routes/AcceptMobileInvite";

import BrokenLink from "components/BrokenLink";

import config from "config";
import {
  USER_DATA_STORAGE_KEY,
  ROUTES,
  REFRESH_TOKEN_URL,
  PREV_LOCATION_KEY,
  SESSION_ID_STORAGE_KEY,
} from "consts";
import { ExternalUserInfo } from "types";
import { ExternalUserTenantOptions, getExternalTenantOptions } from "api/tenantOptions";
import { AxiosError } from "axios";
import { ApiError } from "fe-shared/src/types/common";
import { useQuery } from "react-query";

const anonymousRoutes = [
  ROUTES.login,
  ROUTES.enroll,
  ROUTES.createPin,
  ROUTES.createPassword,
  ROUTES.forgotPassword,
  ROUTES.brokenLink,
  ROUTES.acceptWebInvite,
  ROUTES.acceptMobileInvite,
  ROUTES.inactiveInvite,
  ROUTES.accountCreated,
];

const App = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isUserDataInLocalStorage = !!get(USER_DATA_STORAGE_KEY, null);
  const [userData, setUserData] = useState<ExternalUserInfo | null>(
    get(USER_DATA_STORAGE_KEY, null, !isUserDataInLocalStorage)
  );
  const [isKeepLoginChecked, setIsKeepLoginChecked] = useState(
    isUserDataInLocalStorage
  );
  const { goBack } = useHistoryListener(
    PREV_LOCATION_KEY,
    ROUTES.root,
    anonymousRoutes
  );

  const [, updateTenantOptions] = useTenantOptions();
  
  useEffect(() => {
    set(USER_DATA_STORAGE_KEY, userData, !isKeepLoginChecked);
    if (userData === null) {
      set(USER_DATA_STORAGE_KEY, userData, isKeepLoginChecked);
    }
  }, [userData, isKeepLoginChecked]);

  const sessionId = useSentryIdentify(SESSION_ID_STORAGE_KEY, userData);

  const isLoggedIn = useAuthorizationHeader(userData?.token);

  const { data: tenOptions, isLoading: tenantOptionsIsLoading } = useQuery<
      ExternalUserTenantOptions,
      AxiosError<ApiError>
    >(
      ['externalTenantOptions', userData?.data?.userId],
      () => getExternalTenantOptions(userData?.data?.userId),
      {   
        onSuccess: (data) => {
          updateTenantOptions(data)
      }
    }
  );

  useAccessRejectedInterceptor(userData, setUserData, REFRESH_TOKEN_URL);
  useGlobalErrorHandler();

  useEffect(() => {
    if (!userData && !isDisabledRoute(anonymousRoutes, pathname)) {
      history.replace(ROUTES.login);
    } else if (userData && pathname === ROUTES.login) {
      goBack();
    }
  }, [goBack, history, pathname, userData]);

  useEffect(() => {
    if (isLoggedIn === false) {
      updateTenantOptions(({}));
      setUserData(null);
    }
  }, [isLoggedIn]);

  if (isLoggedIn === null || tenantOptionsIsLoading) {
    return <Loader isFullScreen />;
  }

  return (
    <UserContext.Provider
      value={{
        sessionId,
        user: userData,
        setData: setUserData,
        setIsKeepLoginChecked,
      }}
    >
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} push={false} />
        {!isLoggedIn && (
          <Route path={ROUTES.login}>
            <LoginView tenantName={tenOptions?.tenantName!}/>
          </Route>
        )}
        <Route path={`${ROUTES.createPin}/:createPinToken`}>
          <CreatePin />
        </Route>
        <Route path={`${ROUTES.createPassword}/:createPasswordToken`}>
          <CreatePassword />
        </Route>
        <Route path={ROUTES.forgotPassword}>
          <ForgotPasswordView />
        </Route>
        <Route path={ROUTES.enroll}>
          <ExternalApp
            name="Applications"
            feature="Enrollment"
            host={config.APPLICATIONS_HOST_URL}
            apiBaseUrl={config.API_HOST_URL}
          />
        </Route>
        <Route path={`${ROUTES.brokenLink}/:reason`}>
          <BrokenLink />
        </Route>
        <Route path={ROUTES.accountCreated}>
          <AccountCreated />
        </Route>
        <Route path={ROUTES.inactiveInvite}>
          <InactiveInvite />
        </Route>
        <Route path={`${ROUTES.acceptWebInvite}/:acceptInviteToken`}>
          <AcceptWebInvite />
        </Route>
        <Route path={`${ROUTES.acceptMobileInvite}/:acceptInviteToken`}>
          <AcceptMobileInvite />
        </Route>
        {isLoggedIn && (
          <Route path={ROUTES.root}>
            <ExternalAppsView />
          </Route>
        )}
        <Route path="*">
          <NotFoundPage returnLink={ROUTES.root} />
        </Route>
      </Switch>
    </UserContext.Provider>
  );
};

export default App;
